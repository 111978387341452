<template>
  <div>
    <el-dialog title="修改标签"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">

      <el-row :gutter="10">
        <el-col :span="4"
                style="line-height:36px;font-size:15px">新标签:</el-col>
        <el-col :span="18">
          <el-input clearable
                    placeholder="请输入"
                    v-model="form.discard_tags"></el-input>
        </el-col>
      </el-row>
      <span slot="footer"
            class="dialog-footer">
        <!-- <el-button @click="handleClose">取 消</el-button> -->
        <el-button type="primary"
                   @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    save () {
      this.$http({
        url: '/api/v1/question/discard_edit',
        method: 'post',
        data: {
          id: this.form.question_discard_id,
          tags: this.form.discard_tags
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '修改成功!',
          type: 'success'
        });
        this.$parent.refresh()
        this.handleClose()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>