<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :span="6">
        <el-select style="width:100%"
                   v-model="subject_id"
                   @change="search"
                   placeholder="选择科目">
          <el-option v-for="item in subjectList"
                     :key="item.subject_id"
                     :label="item.subject_name"
                     :value="item.subject_id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-input clearable
                  style="width:100%"
                  @keyup.enter.native="search"
                  v-model="favorite_tags"
                  placeholder="标签"></el-input>
      </el-col>
      <el-col :span="6">
        <el-button type="primary"
                   @click="search">确定</el-button>
      </el-col>
    </el-row>

    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      subjectList: [],
      subject_id: '',
      favorite_tags: '',
      tableData: [],
      tableLoading: false,
      showList: [],
      pageName: 'cz_testingCentre_admin',
      tableColumns: [
        { prop: 'question_discard_id', align: 'center', label: 'ID', width: '100', showToopic: false },
        { prop: 'question_id', align: 'center', label: '题目ID', width: '100', showToopic: true },
        { prop: 'time_input', align: 'center', label: '丢弃时间', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '科目', width: '', showToopic: false },
        { prop: 'question_type_name', align: 'center', label: '题目类型', width: '', showToopic: false, },
        { prop: 'question_category_name', align: 'center', label: '题目类别', width: '', showToopic: false, isStatus: true },
        { prop: 'discard_tags', align: 'center', label: '标签', width: '100', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '200', type: 'img', btns: [
            { content: '查看', type: 'look', event: 'toInfo' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ]
    }
  },
  components: {
    Form
  },
  mounted () {
    this.getSubject()
    this.setTablesColums()
  },
  methods: {
    getClass (prop, value) {
      return ''
    },
    getValue (prop, value, row) {
      if (prop == 'question_category_name') {
        var str = ''
        if (row.subject_id == 7 || row.subject_id == 11) {
          str = row.question_category_name
        }
        return str
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      this.tableLoading = true
      this.$http({
        url: '/api/v1/question/discard',
        method: 'get',
        params: {
          page,
          limit,
          subject_id: this.subject_id,
          discard_tags: this.favorite_tags
        }
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
      })
    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.subject
        this.subject_id = this.subjectList[0].subject_id
        this.search()
      })
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/question/discard_del',
          method: 'post',
          data: {
            id: row.question_discard_id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        this.refresh()
      })
    },
    showForm (row) {
      let form = JSON.parse(JSON.stringify(row))
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    toInfo (row) {
      this.$router.push('/mydiscard/info?id=' + row.question_id)
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    setList (list) {
      this.showList = list
    },
    mydiscard () {
      this.$router.push('/mydiscard')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>
